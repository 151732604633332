'use strict';

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
const dataLayer = window.dataLayer || [];
var events = {
    homeshow: function () {
        $(function () {
            var deviceType = 'Desktop';
            var ua = navigator.userAgent;

            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                deviceType = 'Tablet';
            }

            if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                deviceType = 'Mobile';
            }
            dataLayer.push({
                event: 'customEvent',
                deviceType: deviceType,
                pageType: 'Homepage'
            });
        });

        // Main navigation click event
        $('.navbar .navbar-nav .nav-item .nav-link').on('click', function () {
            var $ele = $(this).css('text-transform', 'capitalize');
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Main Navigation',
                label: $ele[0].innerText,
                clickUrl: $(this).prop('href')
            });
        });

        //// Header find a store click event for Rebranding
        $('.header_Redesign .header-top-inner .store-locate').on('click', function () {
            var $ele = $(this).css('text-transform', 'none !important');
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Top Navigation',
                label: $ele[0].innerText,
                clickUrl: $(this).prop('href')
            });
        });

        // Header find a store click event
        $('.header-top-inner .store-locate').on('click', function () {
            var $ele = $(this).css('text-transform', 'capitalize');
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Top Navigation',
                label: $ele[0].innerText,
                clickUrl: $(this).prop('href')
            });
        });


        // sub category
        $('#products + .dropdown-menu .dropdown-item .dropdown-menu .dropdown-item .dropdown-link').on('click', function (){
            var $ele = $(this).css('text-transform','capitalize');
            var ua = navigator.userAgent;
         if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            dataLayer.push({
                event: 'main_nav_product_click',
                category: $ele[0].offsetParent.children[1].innerText,
                clickText: $ele[0].innerText,
                clickUrl:$(this).attr('href')
            }); 
            } else{
                dataLayer.push({
                    event: 'main_nav_product_click',
                    category: $ele[0].offsetParent.children[0].innerText,
                    clickText: $ele[0].innerText,
                    clickUrl:$(this).attr('href')
                }); 
            }
        });
      
        //Instagram
        $('.experience-commerce_assets-instagram .ig-gallery-title a').on('click', function () {
            dataLayer.push({
                event: 'follow_us_on_insta_click_redirect',
                clickText: $(this)[0].innerText,
                clickUrl: $(this).attr('href')
            });         
        });
        
        $('body').on('click', '.experience-commerce_assets-instagram button.slick-prev', function () {
            dataLayer.push({
                event: 'instagram_carousel_slider_view',
                carousel_name: 'instagram feed carousel',
                slide_direction: 'left slide',
                total_slides: 10
            });
        });

        $('body').on('click', '.experience-commerce_assets-instagram button.slick-next', function () {
            dataLayer.push({
                event: 'instagram_carousel_slider_view',
                carousel_name: 'instagram feed carousel',
                slide_direction: 'right slide',
                total_slides: 10
            });
        });

        $('body').on('click', '.experience-commerce_assets-instagram .ig-gallery .slick-list .slick-slide', function () {
            dataLayer.push({
                event: 'instagram_post_click_redirect',
                click_index: ($(this).index()) + 1,
                carousel_name: 'instagram feed carousel'
            }); 
        });
    },
    productshow: function () {
        $(function () {
            var deviceType = 'Desktop';
            var ua = navigator.userAgent;

            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                deviceType = 'Tablet';
            }

            if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                deviceType = 'Mobile';
            }
            dataLayer.push({
                event: 'customEvent',
                category: 'Product pages',
                deviceType: deviceType,
                pageType: 'Product',
                productID: document.getElementsByClassName('product-detail')[0].getAttribute('data-pid'),
                productName: document.getElementsByClassName('product-name')[0].innerText,
                catalogCategoryTitle: 'Product Categories'
            });
        });
    },
    productshowincategory: function () { },
    searchshow: function () {
        if (window.gtmEnabled) {
            $('body').on('click', '.product .image-container a:not(.quickview), .product .pdp-link a', function () {
                var $ele = $(this).closest('.product');
                var gtmdata = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
                productClick(gtmdata);
            });
        }

        // Category to DataLayer
        $(function () {
            var gtmdata = $('.page').attr('data-querystring');
            gtmdata = gtmdata.substring(gtmdata.indexOf('=') + 1);
            gtmdata = gtmdata.split('&')[0];
            addCategory(gtmdata);
        });

        $('.fa-heart-o').on('click', function () {
            dataLayer.push({
                event: 'wishlist',
                ecommerce: {
                    product: JSON.parse($(this).parents('.product').attr('data-gtmdata'))
                }
            });
        });

        $(function () {
            var deviceType = 'Desktop';
            var ua = navigator.userAgent;

            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                deviceType = 'Tablet';
            }

            if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
                deviceType = 'Mobile';
            }
            var dataSearchKeywords = $('.container.search-results').attr('data-searchkeywords');

            var pidsProductsName = [];
            var pidsProductsId = [];
            var products = $('.product');
            for (let i = 0; i < products.length; i += 1) {
                var product = JSON.parse($(products[i]).attr('data-gtmdata'));
                pidsProductsId.push(product.id);
                pidsProductsName.push(product.name);
            }
            var eventDatalayer = {
                event: 'customEvent',
                deviceType: deviceType,
                pageType: (dataSearchKeywords != null) ? 'Search' : 'PLP',
                catalogCategoryTitle: 'Product Categories',
                searchResultsProducts: pidsProductsName,
                searchResultsProductIds: pidsProductsId
            };
            if (dataSearchKeywords) {
                eventDatalayer.searchKeyword = dataSearchKeywords;
            } else {
                eventDatalayer.catalogCategoryId = $('.container.search-results').attr('data-categoryid');
                eventDatalayer.catalogSubCategoryTitle = $('.container.search-results').attr('data-categoryname');
            }

            dataLayer.push(eventDatalayer);
        });

        $(function () {
            var searchKeyword = $('.container.search-results').attr('data-searchkeywords');
            var num_search_results = $('.search-result-count').attr('data-result-count');

            if (searchKeyword) {
                dataLayer.push({
                    event: 'search',
                    search_term: searchKeyword,
                    num_search_results: num_search_results,
                    page_name: $('title').text().trim(),
                    page_location: window.location.href,
                    hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
                });
            }
        });

        // sub category
        $('#products + .dropdown-menu .dropdown-item .dropdown-menu .dropdown-item .dropdown-link').on('click', function (){
            var $ele = $(this).css('text-transform','capitalize');
            var ua = navigator.userAgent;
         if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            dataLayer.push({
                event: 'main_nav_product_click',
                category: $ele[0].offsetParent.children[1].innerText,
                clickText: $ele[0].innerText,
                clickUrl:$(this).attr('href')
            }); 
            } else{
                dataLayer.push({
                    event: 'main_nav_product_click',
                    category: $ele[0].offsetParent.children[0].innerText,
                    clickText: $ele[0].innerText,
                    clickUrl:$(this).attr('href')
                }); 
            }
        });
    },
    cartshow: function () { },
    checkoutbegin: function () { },
    orderconfirm: function () { },
    pageshow: function (){
        // sub category
        $('#products + .dropdown-menu .dropdown-item .dropdown-menu .dropdown-item .dropdown-link').on('click', function (){
            var $ele = $(this).css('text-transform','capitalize');
            var ua = navigator.userAgent;
         if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            dataLayer.push({
                event: 'main_nav_product_click',
                category: $ele[0].offsetParent.children[1].innerText,
                clickText: $ele[0].innerText,
                clickUrl:$(this).attr('href')
            }); 
            } else{
                dataLayer.push({
                    event: 'main_nav_product_click',
                    category: $ele[0].offsetParent.children[0].innerText,
                    clickText: $ele[0].innerText,
                    clickUrl:$(this).attr('href')
                }); 
            }
        });   
    },
    // events that should happen on every page
    all: function () {
        // Add data Action
        $(function () {
            var dataaction = $('.page').attr('data-action');
            if (dataaction) {
                dataLayer.push({
                    event: 'page',
                    'data-action': dataaction
                });
            }
        });

        // Add to Cart
        $('body').on('click', '.add-to-cart, .add-to-cart-global', function () {
            if (!$(this).hasClass('isDisabled') && !$(this).hasClass('disabled')) {
                var $ele = $(this);
                var gtmData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
                var gtmGA4Data = $ele.data('gtmga4data') || $.parseJSON($ele.attr('data-gtmga4data'));
                var qty = $ele.closest('.product-wrapper').find('.quantity-select').val();
                qty = qty || 1;

                if (window.gtmEnabled) {
                    addToCart(gtmData, qty);
                }

                if (window.gtmGA4Enabled) {
                    addToCartGA4(gtmGA4Data, qty);
                }
            }
        });

        // Remove from Cart
        $('body').on('click', '.remove-product', function () {
            var $ele = $(this);
            var gmtData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
            var gtmGA4Data = $ele.data('gtmga4data') || $.parseJSON($ele.attr('data-gtmga4data'));
            var qty = $ele.closest('.card').find('select.quantity').val();
            qty = qty || 1;

            $('body').on('click', '#removeProductModal .cart-delete-confirmation-btn', function () {
                if (window.gtmEnabled) {
                    removeFromCart(gtmData, qty);
                }

                if (window.gtmGA4Enabled) {
                    removeFromCartGA4(gtmGA4Data, qty);
                }
            });
        });

        // Update GTM data attribute
        $('body').on('product:updateAddToCart', function (e, response) {
            $('button.add-to-cart, button.add-to-cart-global', response.$productContainer)
                .attr('data-gtmdata', JSON.stringify(response.product.gtmData))
                .attr('data-gtmga4data', JSON.stringify(response.product.gtmGA4Data));
        });

        // Nav Bar Primary Button click
        $('body').on('click', '.nav-item a', function () {
            dataLayer.push({
                elementType: 'link',
                nav1: $(this).text().trim(),
                event: 'click'
            });
        });

        // interacting with the e-retailer logos
        $('body').on('click', '.track-nav-tile-component a', function () {
            var retailerName = $(this).find('h2.nav-title').html().replace(/\n/g, '')
                .trim();
            if (retailerName === '') {
                retailerName = $(this).find('img').attr('alt').trim();
            }
            dataLayer.push({
                event: 'e_retailer_click',
                e_retailer_name: retailerName,
                page_name: $('title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            });
        });

        // AB testing
        $('body').on('click', '#homepage .Ab-image a', function (){
            var abtestName = $(this).find('img').attr('alt').trim();
            var name = $(this).find('span')[0].attributes.hidden.value;
            dataLayer.push({
                event: 'ab_test',
                experimentName: name,
                experimentVersion: abtestName
            });  
        });
         
        // Some brands have a different path / component to the discover more from Cetaphil 
        $('body').on('click', '#homepage .experience-commerce_assets-categoryTile a', function () {
            var $this = $(this).closest('.experience-commerce_assets-categoryTile');
            var $catLink = $this.find('.cat-link-below, .cat-link');
            dataLayer.push({
                event: 'homepage_discover_more_click',
                link_text: $catLink.text().trim(),
                widget_name: $catLink.text().trim(),
                link_url: $this.find('a').prop('href'),
                page_name: $('title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            }, {
                event: 'homepage_clicks',
                category: 'Discover More',
                label: $catLink.text().trim(),
                clickUrl: $this.find('a').prop('href')
            });
        });

        // interacting social logo at the bottom of the page
        $('body').on('click', '.social-links li', function () {
            var $href = $(this).find('a').attr('href');
            var $socialNetwork = $(this).find('a').attr('aria-label');

            dataLayer.push({
                event: 'social_network_click',
                social_network: $socialNetwork,
                link_url: $href,
                page_name: $('title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            });
        });

        // interacting with specific product find in store
        $('body').on('click', '.find-in-store-container .store-locate', function () {
            dataLayer.push({
                event: 'find_in_store_click',
                item_name: $('h2.product-name').text().trim(),
                page_name: $('head > title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            });
        });

        // User clicks on the search result
        $('body').on('click', 'div.product-grid .grid-tile-wrapper ', function () {
            var searchKeyword = $('.container.search-results').attr('data-searchkeywords');
            if (searchKeyword) {
                dataLayer.push({
                    event: 'search_select_result',
                    search_term: searchKeyword,
                    search_result_url: $(this).find('a').prop('href'),
                    search_result_position: ($(this).index()) + 1,
                    page_name: $('title').text().trim(),
                    page_location: window.location.href,
                    hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
                });
            }
        });

        // product size options
        $('body').on('change', '.attribute select.custom-select', function () {
            var option_value = $('.attribute select.custom-select').val();
            var option_text = $(`option[value='${option_value}']`).text().trim();

            dataLayer.push({
                event: 'product_size_click',
                product_category: $('.breadcrumb-item').last().find('[itemprop="name"]').html()
                    .trim(),
                product_size_number: option_text,
                item_name: $('h1.product-name').text().trim(),
                page_name: $('title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            });
        });

        // buy now
        $('body').on('click', '.internal-cs-ga4', function () {
            dataLayer.push({
                event: 'buy_now_click',
                item_name: $('h1.product-name').text().trim(),
                page_name: $('title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            });
        });

        // adding Add the DataLayer to the "learn more / Find out more" button on the homepage when clicked
        $('body').on('click', '#homepage .learn-more-tracking a', function () {
            dataLayer.push({
                event: 'homepage_learn_more_click',
                link_text: $(this).text().trim(),
                link_url: $(this).attr('href'),
                page_name: $('title').text().trim(),
                page_location: window.location.href,
                hit_timetamp: new Date().toLocaleTimeString([], { hour12: false })
            });
        });

        // Skincare-assist button
            $('body').on('click', '#skincare-assist .experience-commerce_assets-button a.btn-primary', function () {
            var analyze_click = $(this)[0].innerText;
            if (analyze_click === 'ANALYZE YOUR SKIN')  {
            dataLayer.push({
                event: 'analyze_your_skin_click',
                clickText: 'Analyze Your Skin',
                clickUrl: $(this).attr('href')
            });
            }         
        });

        // Main Banner CTA event
        $('body').on('click', '#homepage .learn-more-tracking a.btn-primary', function () {
            var $ele = $(this).css('text-transform','capitalize');
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Main Banner CTA',
                label: $ele[0].innerText,
                clickUrl:$(this).attr('href')
            });
        });

        // Homepage Content buttons
        $('body').on('click', '#homepage .button-component .btn-outline-primary', function () {
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Content Highlight',
                label: $(this)[0].innerHTML,
                clickUrl: $(this).attr('href')
            });
        });

        
        $('body').on('click', '#homepage .learn-more-tracking a', function () {
            dataLayer.push({
                event: 'analyze_your_skin_click',
                link_text: $(this).text().trim(),
                link_url: $(this).attr('href'),
                page_name: $('title').text().trim()
            });
        });

        // Homepage Content links
        $('#homepage .editorialRichText-component-container a u').on('click', function () {
            var $ele1 = $(this).closest('.experience-commerce_assets-editorialRichText');
            var $ele2 = $ele1.parent().find('.experience-commerce_assets-editorialRichText .row h3');
            var $heading;
            if ($ele2.length > 1) {
                $heading = $ele2[0].innerHTML.trim() + ' ' + $ele2[1].innerHTML.trim();
            } else {
                $heading = $ele2[0].innerHTML;
            }
            var $link = $(this).closest('a');
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Content Highlight',
                label: $heading,
                clickUrl: $link.prop('href')
            });
        });

        // Homepage Carousel links
        $('body').on('click', '#homepage .pd-generic-carousel .product-tile-image a', function () {
            var $linkText = $(this).parent().next().find('.pdp-link a');
            dataLayer.push({
                event: 'homepage_clicks',
                category: 'Product Carousel',
                label: $linkText[0].innerHTML,
                clickUrl: $(this).attr('href')
            });
        });

    }
};

/**
 * @param {string} categoryObject The category ID
 * @description gets the data for a product click
 */
function addCategory(categoryObject) {
    var obj = {
        event: 'addCategory',
        ecommerce: {
            load: {
                actionField: { list: 'Search Results' }
            }
        }
    };
    obj.ecommerce.load.category = categoryObject;
    dataLayer.push(obj);
}

/**
 * @param {Object} productObject product data
 * @description gets the data for a product click
 */
function productClick(productObject) {
    var obj = {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: { list: 'Search Results' },
                products: []
            }
        }
    };
    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCart(productObject, quantity) {
    var quantObj = { quantity: quantity };
    var obj = {
        event: 'addToCart',
        ecommerce: {
            add: {
                products: []
            }
        }
    };
    obj.ecommerce.add.products.push($.extend(productObject, quantObj));

    dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCartGA4(productObject, quantity) {
    var quantObj = { quantity: quantity };
    var obj = {
        event: 'add_to_cart',
        ecommerce: {
            currency: productObject.currency,
            items: [$.extend(productObject, quantObj)],
            value: (Number(productObject.price) * Number(quantity)).toFixed(2)
        }
    };

    dataLayer.push(obj);
}

/**
 * @function removeFromCart
 * @description Click event for remove product from cart
 */
function removeFromCart(productObject, quantity) {
    var quantObj = { quantity: quantity };
    var obj = {
        event: 'removeFromCart',
        ecommerce: {
            remove: {
                products: []
            }
        }
    };
    obj.ecommerce.remove.products.push($.extend(productObject, quantObj));

    dataLayer.push(obj);
}

/**
 * @function removeFromCartGA4
 * @description Click event for remove product from cart
 */
function removeFromCartGA4(productObject, quantity) {
    var quantObj = { quantity: quantity };
    var obj = {
        event: 'remove_from_cart',
        ecommerce: {
            currency: productObject.currency,
            items: [$.extend(productObject, quantObj)],
            value: (Number(productObject.price) * Number(quantity)).toFixed(2)
        }
    };

    dataLayer.push(obj);
}

/**
 * @function pushEvent
 * @description Convenience method for creating a click event.
 * @param {string} event
 * @param {string} eventCategory
 * @param {string} eventAction
 * @param {string} eventlabel
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

/**
 * @function init
 * @description Initialize the tag manager functionality
 * @param {String} nameSpace The current name space
 */
$(document).ready(function () {
    if (typeof pageAction !== 'undefined' && pageAction && events[pageAction]) {
        events[pageAction]();
    }
    var abtestName = $('#homepage .Ab-image a').find('img').attr('alt').trim();
    var name = $('#homepage .Ab-image a').find('span')[0].attributes.hidden.value;
    dataLayer.push({
        experimentName: name,
        experimentVersion: abtestName
    });
    events.all();

    var queryString = window.location.search;
    var parameters = new URLSearchParams(queryString);
    if (parameters) {
        var crmId = parameters.get('crmid');
    }

    var cookie = document.cookie.split(';').find(cookiename => {
        var cookieId = cookiename.replace(/ /g, '');
        if (cookieId.split('=')[0] === '_ga') {
            return cookieId;
        }
    });

    var cookieValue = cookie ? cookie.split('=')[1].split('.').slice(2).join('.') : null;

    var eventDatalayer = {
        event: 'traking_user',
        cookieId: cookieValue
    };

    if (crmId) {
        eventDatalayer.crmId = crmId;
    }

    dataLayer.push(eventDatalayer);
});

/**
 * listener for ajax events
 */
function gtmEventLoader() {
    try {
        $(document).ajaxSuccess(function (event, request, settings, data) {
            if (settings.dataTypes.indexOf('json') > -1) {
                if (data && '__gtmEvents' in data && Array.isArray(data.__gtmEvents)) {
                    data.__gtmEvents.forEach(function gtmEvent(gtmEvent) {
                        if (gtmEvent) {
                            dataLayer.push(gtmEvent);
                        }
                    });
                }
            }
        });
        document.removeEventListener('DOMContentLoaded', gtmEventLoader);
    } catch (e) {
        console.error(e);
    }
}

/**
 * setup ajax event listener
 */
if (document.readyState === 'complete') {
    gtmEventLoader();
} else {
    document.addEventListener('DOMContentLoaded', gtmEventLoader);
}
